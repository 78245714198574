<template>
  <div>
    <el-button
      type="primary"
      size="default"
      @click="$router.back"
      circle
      :icon="backIcon"
    ></el-button>
    <el-row :gutter="20">
      <MembersTable />
    </el-row>
  </div>
</template>
<script>
import MembersTable from './components/Table.vue'
import { mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import { Back } from '@element-plus/icons-vue'
export default {
  components: { MembersTable },
  computed: {    backIcon() {
      return Back
    },
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
      member: 'auth/member',
      token: 'auth/token',
      organisations: 'organisations/organisations',
    }),
    ...mapFields('devices', ['filter']),
  },
  mounted() {
 
    this.$store.dispatch('organisations/getMembers', {
      params: { organizationId: this.$route?.params?.id ?? null },
    })
  },
  methods: {

  },
}
</script>
