<template>
  <el-container>
    <el-header style="display: flex; justify-content: space-between; align-items: center;">
      <span>Members</span>
      <span v-if="totalElements > 0">{{ members.length }}</span>
      <MembersEdit :action="'create'"/>
    </el-header>
    <el-main>
      <el-table stripe :data="members" style="width: 100%">
        <el-table-column label="Nom"  prop="user.fullname"> </el-table-column>
        <el-table-column label="Rôle">
          <template #default="scope" >
            <el-tag> {{ roleLabel(scope.row.role) }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="Date d'expiration" >
          <template #default="scope" >
            {{ expiresAt(scope.row.expiresAt) }}
          </template>
        </el-table-column>
        <el-table-column label="Action" align="right">
          <template #default="scope" >
            <el-space>
              <MembersEdit
                :index="scope.$index"
                :row="scope.row"
                :action="'update'"
              />
              <el-popconfirm
                confirmButtonText="Oui"
                cancelButtonText="Annuler"
                @confirm="handleDelete(scope.row)"
                :icon="InfoIcon"
                iconColor="#F56C6C"
                title="Voulez vous vraiment supprimer cette organisation ?"
              >
                <template #reference>
                  <el-button
                    size="small"
                    type="danger"
                    :icon="DeleteIcon"
                  ></el-button>
                </template>
              </el-popconfirm>
            </el-space>
          </template>
        </el-table-column>
      </el-table>
    </el-main>
  </el-container>
</template>

<script>
import MembersEdit from './Edit.vue'
import { mapGetters, mapActions, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import _ from 'lodash'
import dayjs from 'dayjs'

export default {
  components: { MembersEdit },
  data() {
    return {
      pageSize: 20,
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      isAppUser: 'auth/isAppUser',
      isAppAdmin: 'auth/isAppAdmin',
      organisations: 'organisations/organisations',
      metadata: 'organisations/metadata',
    }),
    ...mapState('organisations', ['members']),
    ...mapFields('devices', ['filter']),
    page() {
      return Number(_.get(this.metadata, 'pageable.pageNumber', 0)) + 1
    },
    totalElements() {
      return _.get(this.metadata, 'totalElements', '0')
    },
  },
  methods: {
    ...mapActions({
      setOrg: 'organisations/setOrg',
      deleteOrg: 'organisations/deleteOrg',
    }),
    formatRetailer(retailer) {
      if (retailer) return retailer.name
    },
    expiresAt(date) {
      if (date) return dayjs(date).format('DD-MM-YYYY')
      else return '--'
    },
    roleLabel(role) {
      switch (role) {
        case 'ORG_ADMIN':
          return 'Admin'

        case 'ORG_MANAGER':
          return 'Manager'

        case 'ORG_USER':
          return 'User'

        default:
          return '--'
      }
    },
    enableOrganization(row) {
      let payload = {
        id: row.id,
        data: {
          enabled: row.enabled,
        },
      }
      this.$store.dispatch('organisations/updateOrganisation', payload)
    },
    handleDelete(row) {
      this.$store
        .dispatch('organisations/deleteMember', {
          organizationId: this.$route.params.id,
          id: row.id,
        })
        .then(() => {})
        .catch((error) => console.log(error))
    },
    setPage(val) {
      this.filter.page = val - 1
      let payload = {
        params: {
          ...this.filter,
        },
      }

      this.getOrganisations(payload)
    },
  },
}
</script>

<style>
.button-yoda:hover {
  background-color: #ffef9f;
  border: #ff9b2e;
  color: #ff9b2e;
}
.button-yoda-user:hover {
  background-color: #c19ee0;
  border: white;
  color: white;
}
form label {
  font-weight: bold;
}
</style>
