<template>
  <el-tooltip class="item" effect="dark" content="Modifier" placement="top">
    <el-button
      :type="action == 'create' ? 'primary' : 'default'"
      :icon="action == 'create' ? PlusIcon : EditIcon"
      @click="action == 'create' ? handleCreate() : handleEdit(row)"
      :size="action == 'create' ? 'default' : 'small'"
    ></el-button>
  </el-tooltip>
  <el-dialog
    :title="action == 'create' ? 'Ajouter un membre' : ''"
    v-model="dialogFormEdit"
    append-to-body
    width="30%"
  >
    <el-form ref="memberForm" :model="form" label-position="top" :rules="rules">
      <div v-if="action == 'create'">
        <el-form-item label="Email" prop="user" :label-width="formLabelWidth">
          <el-select
            v-model="form.user"
            filterable
            remote
            value-key="key"
            placeholder="Chercher un email"
            :remote-method="remoteMethod"
            :loading="loading"
            @change="handleSelect"
            class="w-100"
          >
            <el-option
              v-for="item in emailOptions"
              :key="item.id"
              :label="item.email"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <el-form-item label="Role" prop="role" :label-width="formLabelWidth">
        <el-select v-model="form.role" placeholder="Role" class="w-100">
          <el-option label="Admin" value="ORG_ADMIN"></el-option>
          <el-option label="Manager" value="ORG_MANAGER"></el-option>
          <el-option label="User" value="ORG_USER"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="Date d'expiration"
        prop="expiresAt"
        :label-width="formLabelWidth"
      >
        <el-date-picker v-model="form.expiresAt" type="date" class="w-100">
        </el-date-picker>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormEdit = false" type="danger" plain
          >Annuler</el-button
        >
        <el-button
          type="primary"
          @click.stop.prevent="
            action == 'create' ? create('memberForm') : update('memberForm')
          "
          >Confirmer</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import {
  REGION_OPTIONS,
  TIMEZONE_OPTIONS,
  CURRENCY_OPTIONS,
} from '../../../helpers/StaticData'
import { clean } from '../../../utils/index'

export default {
  props: {
    action: {
      type: String,
      default: 'create',
    },
    index: {
      type: Number,
      default: 0,
    },
    row: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      emailOptions: [],
      selectedUser: null,
      REGION_OPTIONS,
      TIMEZONE_OPTIONS,
      CURRENCY_OPTIONS,
      dialogFormEdit: false,
      radio: 3,
      formLabelWidth: '150px',
      form: {
        user: null,
        role: '',
        expiresAt: '',
      },
      retailerOptions: [],
      rules: {
        user: [
          {
            required: true,
            message: "Veuillez saisir l'email",
            trigger: 'change',
          },
        ],
        role: [
          {
            required: true,
            message: 'Veuillez saisir le rôle',
            trigger: 'change',
          },
        ],
      },
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      isAppAdmin: 'auth/isAppAdmin',
      organisations: 'organisations/organisations',
      org: 'organisations/organisation',
      retailers: 'retailers/retailers',
    }),
  },
  watch: {},
  methods: {
    ...mapActions({
      setOrg: 'organisations/setOrg',
    }),
    create(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          let payload = {
            data: {
              ...clean(this.form),
              organization: { id: this.$route.params.id },
            },
          }
          this.$store
            .dispatch('organisations/addMember', payload)
            .then(() => {
              this.dialogFormEdit = false
            })
            .catch((error) => console.log(error))
        }
      })
    },
    update(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          let payload = {
            id: this.row.id,
            data: {
              ...clean(this.form),
              organization: { id: this.$route.params.id },
            },
          }

          this.$store
            .dispatch('organisations/updateMember', payload)
            .then(() => {
              this.dialogFormEdit = false
            })
            .catch((error) => console.log(error))
        }
      })
    },
    handleSelect(value) {
      let result = this.emailOptions.find((item) => item.email == value)
      if (result !== undefined) this.selectedUser = result
    },
    remoteMethod(query) {
      if (query) {
        this.loading = true
        this.$store
          .dispatch('users/getUsersByQuery', { params: { q: query } })
          .then((data) => {
            this.emailOptions = data
            this.loading = false
          })
      } else {
        this.emailOptions = []
      }
    },
    handleCreate() {
      this.reset()
      this.dialogFormEdit = true
    },
    handleEdit(row) {
      this.dialogFormEdit = true
      this.populate(row)
      this.setOrg(row)
    },
    reset() {
      this.form.role = null
      this.form.expiresAt = null
    },
    populate(row) {
      this.form.role = row.role ?? null
      this.form.expiresAt = row.expiresAt ?? null
    },
    getRetailers() {
      if (!this.isAppAdmin) return
      this.loading = true
      if (this.retailers.length > 0) {
        this.retailerOptions = this.retailers
        this.loading = false
        return
      }
      this.$store
        .dispatch('retailers/getRetailers', {})
        .then(() => {
          this.retailerOptions = this.retailers
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
